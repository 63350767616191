<template>
  <div id="livraison">
    <div v-if="isSendAvailable && isDepositAvailable">
      <v-radio-group v-model="type" row>
        <v-row>
          <v-col
            v-for="sortedType in sortedTypes"
            :key="sortedType.value"
            cols="12"
            md="6"
            style="cursor: pointer"
            @click="onClickSortedType(sortedType)"
          >
            <v-card class="fill-height d-flex justify-space-between px-5">
              <v-icon>{{ sortedType.icon }}</v-icon>
              <div class="w-75 fill-height d-flex flex-column align-items-baseline justify-center">
                <v-card-title>{{ sortedType.title }}</v-card-title>
                <v-card-subtitle>{{ sortedType.subtitle }}</v-card-subtitle>
              </div>
              <v-radio :value="sortedType.value" class="mr-0"/>
            </v-card>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>
    <div class="mt-4">
      <v-banner
        v-if="type"
        :style="`background-color: ${environment.theme.bannerBackgroundColor}`"
        class="text-center mb-5"
      >
        <p class="h6 m-0">
          <span v-if="type === 'send'">
            Les frais de livraison sont estimés en fonction des articles dans votre panier, le prix correspond à l’aller
            et le retour du colis.
          </span>
          Les délais estimés dépendent de la date de dépôt de votre commande et des aléas transporteur.
        </p>
      </v-banner>
      <v-expansion-panels id="shippings" v-model="expanded">
        <v-expansion-panel v-if="showMondialRelay">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="12" md="5" xl="4">
                <h4>Livraison par Mondial Relay</h4>
              </v-col>
              <v-col cols="12" md="7" xl="8">
                <h6>
                  {{ estimatedDelays.mr }} -
                  {{ formatPrice(shipmentOptions.MONDIAL_RELAY.value) }}
                  <span v-if="shipmentOptions.MONDIAL_RELAY.prix_normal" class="through ml-1">
                    {{ formatPrice(shipmentOptions.MONDIAL_RELAY.prix_normal) }}
                  </span>
                </h6>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-if="isMondialRelayDisabled" class="text-center error--text">
              <v-icon class="error--text">mdi-alert</v-icon>
              Les services de Mondial Relay ne sont pas disponibles pour cause de maintenance.<br/>
              Vous pourrez à nouveau choisir ce transporteur à partir du
              {{ mondialRelayDisabling.to.toLocaleString() }}
            </div>
            <template v-else>
              <v-radio-group v-model="radioGroup">
                <v-radio
                  :label="formatPrice(shipmentOptions.MONDIAL_RELAY.value)"
                  :value="shipmentOptions.MONDIAL_RELAY.cle"
                  @click="setShipment(shipmentOptions.MONDIAL_RELAY, false)"
                />
              </v-radio-group>
              <widget-mondial-relay
                :allowed-countries="['FR', 'ES', 'BE', 'NL', 'LU', 'DE', 'AT']"
                :brand="mondialRelayBrand"
                :default-country="cards.customerInfo.country_code"
                :default-post-code="cards.customerInfo.zip_code"
                :translations="{
                  headerTitle: 'Sélectionnez votre relais',
                  findCpText: 'CP',
                  findCityText: 'Ville',
                  cityNoResults: 'pas de résulat',
                  btnListMobile: 'Liste',
                  btnMapMobile: 'Carte'
                }"
                @select="selectMondialRelayParcel($event)"
                @hook:mounted="mondialRelayMounted"
              />
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="showColissimo">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="12" md="5" xl="4">
                <h4>Livraison par Colissimo</h4>
              </v-col>
              <v-col cols="12" md="7" xl="8">
                <h6>
                  {{ estimatedDelays.colissimo }} -
                  {{ formatPrice(shipmentOptions.COLISSIMO.value) }}
                </h6>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-radio-group v-model="radioGroup">
              <v-radio
                :label="formatPrice(shipmentOptions.COLISSIMO.value)"
                :value="shipmentOptions.COLISSIMO.cle"
                @click="setShipment(shipmentOptions.COLISSIMO)"
              />
            </v-radio-group>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="showWorkshops">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="12" md="5" xl="4">
                <h4>Dans nos ateliers</h4>
              </v-col>
              <v-col cols="12" md="7" xl="8">
                <h6>{{ estimatedDelays.atelier }} - Gratuit</h6>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul v-if="workshops" class="m-0 p-0">
              <li v-for="(item) in workshops" :key="item.cle">
                <v-radio-group v-model="radioGroup">
                  <v-radio
                    :checked="selectedShipping.cle === item.cle"
                    :disabled="item.disabled || false"
                    :value="item.cle"
                    @click="setShipment(item)"
                  >
                    <template v-slot:label>
                      <div :class="{disabled: item.disabled}">
                        <b v-if="item.titre">{{ item.titre }}</b>
                        <div v-if="item.description" v-html="item.description"></div>
                        <div v-if="!item.disabled" class="font-weight-bold">{{ formatPrice(item.value) }}</div>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel v-if="showPartners">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="12" md="5" xl="4">
                <h4>Dans nos points partenaires</h4>
              </v-col>
              <v-col cols="12" md="7" xl="8">
                <h6>{{ estimatedDelays.pr }} - À partir de 2€90</h6>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <ul v-if="partners" class="m-0 p-0">
              <li v-for="(item) in partners" :key="item.cle">
                <v-radio-group v-model="radioGroup">
                  <v-radio :disabled="item.disabled || false" :value="item.cle" @click="setShipment(item)">
                    <template v-slot:label>
                      <div :class="{ disabled: item.disabled }">
                        <b v-if="item.titre">{{ item.titre }}</b>
                        <div v-if="item.description" v-html="item.description"></div>
                        <div class="font-weight-bold">{{ formatPrice(item.value) }}</div>
                      </div>
                    </template>
                  </v-radio>
                  <div
                    v-if="item.cle === 'pr_entreprise' && shippingAdressSelected && shippingAdressSelected.cle === 'pr_entreprise'">
                    <v-text-field
                      v-model="companyName"
                      :rules="requiredRules"
                      label="Nom de mon entreprise / boutique"
                    />
                  </div>
                </v-radio-group>
              </li>
            </ul>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import shipping from '../assets/data/shipping/index.json';
import WidgetMondialRelay from 'vue-widget-mondial-relay';
import 'vue-widget-mondial-relay/dist/widget-mondial-relay.min.css';
import {mapMutations, mapState} from 'vuex'
import {formatPrice} from "@/helper/PriceHelper";
import {WEIGHTS} from "@/assets/data/weights";

const SHIPMENT_TYPES_ORDER = [
  'mr',
  'colissimo',
  'atelier',
  'pr'
]

const SHIPMENT_DELAYS = {
  mr: {
    from: 25,
    to: 30
  },
  colissimo: {
    from: 15,
    to: 20
  },
  atelier: {
    to: 10
  },
  pr: {
    to: 30
  }
}

const sortByZipCode = (zipCode, countyNo, shipments) => shipments.sort((a, b) => {
  if (a.zip_code === zipCode && a.zip_code === b.zip_code) {
    return 0
  }

  if (a.zip_code === zipCode) {
    return -1
  }

  if (b.zip_code === zipCode) {
    return 1
  }

  if (a.county_no === countyNo && a.county_no === b.county_no) {
    return 0
  }

  if (a.county_no === countyNo) {
    return -1
  }

  if (a.zip_code === null && b.county_no !== countyNo) {
    return -1
  }

  if (b.zip_code === null && a.county_no !== countyNo) {
    return 1
  }

  return 1
})

export default {
  components: {WidgetMondialRelay},
  data() {
    return {
      radioGroup: null,
      shippingChoice: [],
      shippingAdressSelected: null,
      expanded: null,
      mondialRelayBrand: process.env.VUE_APP_MONDIAL_RELAY_BRAND,
      requiredRules: [
        v => !!v || 'Ce champs est requis'
      ],
      types: [
        {
          value: 'deposit',
          icon: 'mdi-store-outline',
          title: 'Déposer',
          subtitle: 'Dans nos ateliers, nos points partenaires ou dans votre entreprise'
        },
        {
          value: 'send',
          icon: 'mdi-package-variant-closed',
          title: 'Envoyer',
          subtitle: 'Grâce aux solutions d\'envoi de colis'
        }
      ],
      type: null,
      mondialRelayDisabling: {
        from: new Date('2024-10-12 21:45:00'),
        to: new Date('2024-10-13 22:30:00')
      }
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('environment-app', ['environment']),
    selectedShipping() {
      return this.cards.shippingInfo || {}
    },
    companyName: {
      get() {
        return this.shippingAdressSelected?.company_name
      },
      set(value) {
        this.shippingAdressSelected.company_name = value

        this.setShipment(this.shippingAdressSelected)
      }
    },
    workshops() {
      const zipCode = this.cards.customerInfo.zip_code
      const countyNo = zipCode.substr(0, 2)

      return sortByZipCode(zipCode, countyNo, this.shippingChoice.workshops)
    },
    partners() {
      const zipCode = this.cards.customerInfo.zip_code
      const countyNo = zipCode.substr(0, 2)

      return sortByZipCode(zipCode, countyNo, this.shippingChoice.partners)
    },
    sortedTypes() {
      const zipCode = this.cards.customerInfo.zip_code
      const countyNo = zipCode?.substr(0, 2)

      if (['44', '85', '69'].includes(countyNo)) {
        return this.types
      }

      return [this.types[1], this.types[0]]
    },
    cartProductTypes() {
      return [...new Set(this.products.map(product => product.level2))]
    },
    mondialRelayOptions() {
      return this.shippingChoice.mondial_relay.options
    },
    isColissimoAvailable() {
      return this.environment.shipments.includes('COLISSIMO') && this.shipmentOptions.COLISSIMO
    },
    isMondialRelayAvailable() {
      return this.environment.shipments.includes('MONDIAL RELAY') &&
        this.shipmentOptions.MONDIAL_RELAY &&
        ['FR', 'ES', 'BE', 'LU'].includes(this.cards.customerInfo?.country_code)
    },
    isWorkshopsAvailable() {
      return this.environment.shipments.includes('WORKSHOPS')
    },
    isPartnersAvailable() {
      return this.environment.shipments.includes('PARTNERS')
    },
    isSendAvailable() {
      return this.isColissimoAvailable || this.isMondialRelayAvailable
    },
    isDepositAvailable() {
      return this.isWorkshopsAvailable || this.isPartnersAvailable
    },
    showColissimo() {
      return this.isColissimoAvailable && this.type === 'send'
    },
    showMondialRelay() {
      return this.isMondialRelayAvailable && this.type === 'send'
    },
    showWorkshops() {
      return this.isWorkshopsAvailable && this.type === 'deposit'
    },
    showPartners() {
      return this.isPartnersAvailable && this.type === 'deposit'
    },
    basketWeight() {
      return this.products.reduce((weight, product) => weight + (WEIGHTS[product.level2] ?? WEIGHTS.DEFAULT), WEIGHTS.PARCEL)
    },
    shipmentOptions() {
      const options = {}

      const mondialRelayOption = this.shippingChoice.mondial_relay.options.find(option => option.maxWeight >= this.basketWeight)
      if (mondialRelayOption) {
        options['MONDIAL_RELAY'] = {...this.shippingChoice.mondial_relay, ...mondialRelayOption, options: null}
      }

      const colissimoOption = this.shippingChoice.colissimo.find(option => option.maxWeight === null || option.maxWeight >= this.basketWeight)
      if (colissimoOption) {
        options['COLISSIMO'] = colissimoOption
      }

      return options
    },
    estimatedDelays() {
      return {
        mr: `Départ de notre atelier estimé ${this.formatDelay('mr')}`,
        colissimo: `Départ de notre atelier estimé ${this.formatDelay('colissimo')}`,
        atelier: `Disponibilité estimée ${this.formatDelay('atelier')}`,
        pr: `Disponibilité estimée ${this.formatDelay('pr')}`,
      }
    },
    isMondialRelayDisabled() {
      return new Date() >= this.mondialRelayDisabling.from && new Date() < this.mondialRelayDisabling.to
    }
  },
  methods: {
    formatPrice,
    ...mapMutations('cart-app', ['setTotalWithPort', 'setShippingInfo', 'setEstimatedDelay']),
    setShipment(shipment, resetDetails = true) {
      if (!resetDetails) {
        shipment = {
          details: this.shippingAdressSelected?.details,
          manager: this.shippingAdressSelected?.manager,
          relay_id: this.shippingAdressSelected?.relay_id,
          relay_details: this.shippingAdressSelected?.relay_details,
          type: this.shippingAdressSelected?.type,
          relay_country: this.shippingAdressSelected?.relay_country,
          ...shipment,
        }
      }

      this.radioGroup = shipment.cle
      this.shippingAdressSelected = shipment
      let price = this.shippingAdressSelected.value + this.cards.totalTTC + (this.cards.insurance?.price ?? 0)
      this.setTotalWithPort(Math.round(price * 100) / 100)
      this.setShippingInfo(this.shippingAdressSelected)
      this.setEstimatedDelay(this.estimatedDelays[shipment.type])

      this.$emit('shipmentMethodSelected', this.shippingAdressSelected)
    },
    selectMondialRelayParcel(parcel) {
      const parcel_address = `${parcel.Nom}<br />${parcel.Adresse1} ${parcel.Adresse2 || ''}<br />${parcel.Pays} ${parcel.CP} ${parcel.Ville}`

      let baseShipment = this.shippingAdressSelected
      if (this.shippingAdressSelected?.type !== 'mr') {
        baseShipment = this.shipmentOptions.MONDIAL_RELAY
        this.radioGroup = 'mondial-relay-0'
      }

      this.shippingAdressSelected = {
        ...baseShipment,
        titre: this.shippingChoice.mondial_relay.titre,
        manager: this.shippingChoice.mondial_relay.manager,
        details: `${parcel_address}<br /><br /><b>HORAIRES</b><br /><br />${parcel.HoursHtmlTable}<hr />`,
        relay_id: parcel.ID,
        relay_country: parcel.Pays,
        type: 'mr',
        relay_details: parcel_address
      }

      this.setShipment(this.shippingAdressSelected)
    },
    mondialRelayMounted() {
      document.querySelector('.mondial-relay-tab.mondial-relay-widget-hide-desktop button').click()
    },
    onClickSortedType(sortedType) {
      this.type = sortedType.value
      this.$scrollTo('#shippings')
    },
    formatDelay(type) {
      const delay = SHIPMENT_DELAYS[type]
      const fromDate = new Date()
      const toDate = new Date()

      if (delay.from && delay.to) {
        fromDate.setDate(fromDate.getDate() + delay.from)
        toDate.setDate(toDate.getDate() + delay.to)

        return `entre le ${fromDate.toLocaleDateString()} et le ${toDate.toLocaleDateString()}`
      }

      if (delay.to) {
        toDate.setDate(toDate.getDate() + delay.to)

        return `le ${toDate.toLocaleDateString()}`
      }

      fromDate.setDate(fromDate.getDate() + delay.from)

      return `le ${fromDate.toLocaleDateString()}`
    }
  },
  created() {
    this.shippingChoice = shipping
    this.expanded = SHIPMENT_TYPES_ORDER.indexOf(this.selectedShipping.type)
    this.radioGroup = this.selectedShipping.cle

    if (!this.isSendAvailable || !this.isDepositAvailable) {
      this.type = this.isSendAvailable ? 'send' : 'deposit'
    }

    if (this.cards.shippingInfo?.type) {
      const typeIndex = ['mr', 'colissimo'].includes(this.cards.shippingInfo.type) ? 1 : 0
      this.type = this.types[typeIndex].value

      this.expanded = ['mr', 'atelier'].includes(this.cards.shippingInfo.type) ? 0 : 1
    }
  }
}
</script>
<style scoped>
#livraison li {
  border-bottom: 1px solid #E1E1E1;
  width: 100%;
  padding: 10px;
}

.through {
  text-decoration: line-through;
  text-decoration-thickness: 0.1rem;
}

.disabled {
  color: #6c757d;
}

@media (min-width: 768px) {
  #livraison {
    padding-left: 16px;
  }
}
</style>
